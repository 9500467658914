import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { getNetworkInfoByChainId, Token } from '@pheasant-network/pheasant-sdk';
import { useMemo, useState } from 'react';
import { formatUnits } from 'viem';
import search from '@/assets/icon/search.svg';
import searchDark from '@/assets/icon/search-dark.svg';
import { useIsDarkModeContext, useSelectedNetworkContext, useTradeTokenTypeIndexContext, } from '@/context';
import { formatNumberWithFourDecimalPlaces, getImage, getSelectableNetwork, isMobileDevice, } from '@/utils';
import { supportChains, supportUsdcChains } from '@/wagmiConfig';
const NetworkModalContent = ({ setIsOpenNetworkModal, networkFlag, selectFromNetwork, selectedToNetworkHandler, setIsFocusOut, balances, }) => {
    const isMainnet = process.env.REACT_APP_IS_MAINNET === 'true';
    const { isDarkMode } = useIsDarkModeContext();
    const { tradeTokenTypeIndex } = useTradeTokenTypeIndexContext();
    const [searchQuery, setSearchQuery] = useState('');
    const isNetworkSelectable = (network, counterpartNetwork, query) => {
        // case when chain name does not include search query, then hide this chain
        if (!network.label.toLowerCase().includes(query.toLowerCase()))
            return false;
        // case when user has not selected the counterpart chain, then show all chains
        if (counterpartNetwork.id === 0)
            return true;
        // case when user has selected the counterpart from chain, then hide this chain
        if (networkFlag === 'to' &&
            network.isL2 &&
            network.id === counterpartNetwork.id)
            return false;
        // check if network and counterpart network can send each other
        const _network = getNetworkInfoByChainId(network.networkID);
        const _counterpartNetwork = getNetworkInfoByChainId(counterpartNetwork.networkID);
        // if selecting from network, then show it in the option
        if (networkFlag === 'from') {
            return true;
        }
        // if counterpart network is L1, then network should have this counterpart network as its part network
        else if (_counterpartNetwork.isL1) {
            if (_counterpartNetwork.chainId !== _network.pairNetworkId) {
                return false;
            }
            return true;
        }
        // if both are L2, then they can bridge if they share the same pair network
        // or if counterpart network is L2, show compatible L1 network
        else {
            return (_network.pairNetworkId === _counterpartNetwork.pairNetworkId ||
                _network.chainId === _counterpartNetwork.pairNetworkId);
        }
    };
    const onClick = (network) => {
        if (networkFlag === 'from') {
            setSelectedFromNetwork(network);
            const toNetwork = getNetworkInfoByChainId(selectedToNetwork.networkID);
            const fromNetwork = getNetworkInfoByChainId(network.networkID);
            // if new from network is L1 and current to network is L2, check if they are compatible.
            // if not, then reset to network
            if (!network.isL2 &&
                toNetwork &&
                toNetwork.pairNetworkId !== network.networkID) {
                setSelectedToNetwork(getSelectableNetwork(0));
                // if both are L2, check if they are compatible.
                // if not, then reset to network
            }
            else if (fromNetwork &&
                !fromNetwork.isL1 &&
                toNetwork &&
                !toNetwork.isL1 &&
                toNetwork.pairNetworkId !== fromNetwork.pairNetworkId) {
                setSelectedToNetwork(getSelectableNetwork(0));
                // if new from network is L2 and current to network is L1, check if they are compatible.
                // if not, then reset to network
            }
            else if (fromNetwork &&
                !fromNetwork.isL1 &&
                toNetwork &&
                toNetwork.isL1 &&
                toNetwork.chainId !== fromNetwork.pairNetworkId) {
                setSelectedToNetwork(getSelectableNetwork(0));
                // if selected same network, then reset to network
            }
            else if (toNetwork && toNetwork.chainId === network.networkID) {
                setSelectedToNetwork(getSelectableNetwork(0));
            }
            selectFromNetwork(network);
        }
        else if (networkFlag === 'to') {
            setSelectedToNetwork(network);
            // if new to network is L1 and current from network is L2, check if they are compatible.
            // if not, then reset from network
            const fromNetwork = getNetworkInfoByChainId(selectedFromNetwork.networkID);
            if (!network.isL2 &&
                fromNetwork &&
                fromNetwork.pairNetworkId !== network.networkID) {
                setSelectedFromNetwork(getSelectableNetwork(0));
            }
            selectedToNetworkHandler();
        }
        setIsFocusOut(true);
        setIsOpenNetworkModal(false);
    };
    const { selectedFromNetwork, setSelectedFromNetwork, selectedToNetwork, setSelectedToNetwork, } = useSelectedNetworkContext();
    // filter networks to show on this modal
    const filteredNetworks = useMemo(() => {
        const targetChains = tradeTokenTypeIndex === Token.USDC ? supportUsdcChains : supportChains;
        const counterpartNetwork = networkFlag === 'from' ? selectedToNetwork : selectedFromNetwork;
        return targetChains
            .map((chain) => getSelectableNetwork(chain.id))
            .filter((network) => isNetworkSelectable(network, counterpartNetwork, searchQuery));
    }, [tradeTokenTypeIndex, searchQuery, selectedFromNetwork, selectedToNetwork]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "relative w-full mx-auto my-4", role: "search", children: [_jsx("input", { className: "w-full border-2 text-neutral-400 dark:text-neutral-200 dark:border-neutral-700 h-10 pr-5 pl-12 rounded-8 text-label_l font-medium focus:outline-none bg-secondary-10 dark:bg-neutral-900", type: "search", name: "search", placeholder: "Search Network", value: searchQuery, onChange: (e) => setSearchQuery(e.target.value), autoComplete: "off", "aria-label": "Search Network" }), _jsx("div", { className: "absolute left-0 top-0 mt-2 ml-4", children: _jsx("img", { className: "inline", src: isDarkMode ? searchDark : search, alt: "Search Icon", style: {
                                width: '1.5rem',
                                height: '1.5rem',
                            } }) })] }), _jsx("div", { className: `max-h-[322px] dark:text-neutral-0 grid grid-cols-2 mobile:grid-cols-1
        ${!isMainnet || isMobileDevice() ? 'network-search' : ''}`, children: filteredNetworks.map((network) => (_jsx("span", { onClick: () => onClick(network), style: { cursor: 'pointer' }, children: _jsx("div", { className: `p-2 space-x-2 space-y-2 hover:bg-primary-500 hover:dark:text-neutral-800 rounded-8  ${network.id ===
                            (networkFlag === 'from'
                                ? network?.networkID
                                : network?.networkID)
                            ? 'border-2 border-primary-500 rounded-8'
                            : ''}`, children: _jsxs("button", { type: "button", className: `w-full font-medium flex justify-between items-center`, "aria-label": `Select ${network.label}`, children: [_jsxs("div", { children: [_jsx("img", { className: "inline border border-neutral-800 rounded-[30px]", src: getImage('network/' + network.img), alt: network.label, style: {
                                                width: `30px`,
                                                height: `30px`,
                                            } }), _jsx("span", { className: "whitespace-nowrap text-label_l pl-2", children: network.label })] }), _jsx("span", { className: "whitespace-nowrap text-label_s", children: `${balances[network.networkID]
                                        ? formatNumberWithFourDecimalPlaces(formatUnits(balances[network.networkID].value, balances[network.networkID].decimals))
                                        : 0} ${tradeTokenTypeIndex === Token.USDC ? 'USDC' : 'ETH'}` })] }) }) }, network.networkID))) })] }));
};
export default NetworkModalContent;
