import axios from 'axios';
import { useEffect } from 'react';
import { constants } from '@/constants';
import { PhaseType, TradeType, useCurrentTxInfo, useModalContext, usePhaseContext, } from '@/context';
import { calculateTradeTime } from '@/utils';
const usePollingStatusWithApi = () => {
    const isMainnet = process.env.REACT_APP_IS_MAINNET === 'true';
    const { isProcessingModalOpen, setIsProcessingModalOpen, setIsCompleteModalOpen, } = useModalContext();
    const { currentTxInfo, setCurrentTxInfo } = useCurrentTxInfo();
    const { phase, setPhase } = usePhaseContext();
    useEffect(() => {
        if (!isProcessingModalOpen)
            return;
        if (currentTxInfo.tradeType === undefined)
            return;
        if (phase !== PhaseType.DEPOSITED &&
            phase !== PhaseType.SENT &&
            phase !== PhaseType.DOWNWARD_ETH_SENT)
            return;
        const intervalId = setInterval(() => {
            if (currentTxInfo.tradeType === TradeType.CCTP) {
                axios
                    .get(`${isMainnet
                    ? process.env.REACT_APP_API_ENDPOINT
                    : process.env.REACT_APP_TESTNET_API_ENDPOINT}/cctpTrades/newestTrade?userAddress=${currentTxInfo.address}&txhash=${currentTxInfo.burnTxHash}&chainId=${currentTxInfo.sourceNetworkId}`)
                    .then((result) => {
                    console.log(result);
                    if (result.data &&
                        result.data.data &&
                        result.data.data.newestTrade &&
                        result.data.data.newestTrade.length > 0 &&
                        result.data.data.newestTrade[0].status ===
                            constants.DB_STATUS_MINT) {
                        const tradeFromApi = result.data.data.newestTrade[0];
                        const tradeTime = calculateTradeTime(tradeFromApi.burnTimestamp, tradeFromApi.mintTimestamp, currentTxInfo.estimateTime);
                        setCurrentTxInfo({
                            ...currentTxInfo,
                            mintTxHash: tradeFromApi.mintTxHash,
                            tradeTime,
                        });
                        clearInterval(intervalId);
                        setIsProcessingModalOpen(false);
                        setIsCompleteModalOpen(true);
                        setPhase(PhaseType.NOTHING_STARTED);
                    }
                })
                    .catch((error) => {
                    console.log(error);
                });
            }
            else {
                const isUpward = currentTxInfo.tradeType === TradeType.UPWARD ? true : false;
                const chainId = isUpward
                    ? currentTxInfo.destinationNetworkId
                    : currentTxInfo.sourceNetworkId;
                const txhash = isUpward
                    ? currentTxInfo.acceptedTxHash
                    : currentTxInfo.newTradeTxHash;
                axios
                    .get(`${isMainnet
                    ? process.env.REACT_APP_API_ENDPOINT
                    : process.env.REACT_APP_TESTNET_API_ENDPOINT}/userTrades?userAddress=${currentTxInfo.address}&txhash=${txhash}&chainId=${chainId}&isUpward=${isUpward}`)
                    .then((result) => {
                    if (result.data && result.data.data && result.data.data.trade) {
                        const tradeFromApi = result.data.data.trade;
                        console.log(tradeFromApi);
                        if (isUpward) {
                            if (tradeFromApi && tradeFromApi.acceptTxHash) {
                                const tradeTime = calculateTradeTime(tradeFromApi.acceptedTxTimestamp, tradeFromApi.acceptTimestamp, currentTxInfo.estimateTime);
                                setCurrentTxInfo({
                                    ...currentTxInfo,
                                    acceptTxHash: tradeFromApi.acceptTxHash,
                                    tradeTime,
                                });
                                clearInterval(intervalId);
                                setIsProcessingModalOpen(false);
                                setIsCompleteModalOpen(true);
                                setPhase(PhaseType.NOTHING_STARTED);
                            }
                        }
                        else {
                            if (tradeFromApi && tradeFromApi.txhash) {
                                const tradeTime = calculateTradeTime(tradeFromApi.newTradeTimestamp, tradeFromApi.sendTimestamp, currentTxInfo.estimateTime);
                                setCurrentTxInfo({
                                    ...currentTxInfo,
                                    txhash: tradeFromApi.txhash,
                                    tradeTime,
                                });
                                clearInterval(intervalId);
                                setIsProcessingModalOpen(false);
                                setIsCompleteModalOpen(true);
                                setPhase(PhaseType.NOTHING_STARTED);
                            }
                        }
                    }
                })
                    .catch((error) => {
                    console.log(error);
                });
            }
        }, 5000);
        return () => clearInterval(intervalId);
    }, [
        phase === PhaseType.DEPOSITED,
        phase === PhaseType.SENT,
        phase === PhaseType.DOWNWARD_ETH_SENT,
    ]);
};
export default usePollingStatusWithApi;
