import { getChains } from '@wagmi/core';
import { wagmiConfig, getWagmiConfig } from '@/wagmiConfig';
import { isHealthyRpcWithBalance, getHealthyRpcWithBalance, getRpcUrls, } from '@pheasant-network/pheasant-sdk';
export const getHealthyWagmiConfig = async (chainId, address) => {
    const newChain = getChains(wagmiConfig).find((c) => c.id === chainId);
    console.log(`${newChain.rpcUrls.default.http[0]} is healthy: ${await isHealthyRpcWithBalance(newChain.rpcUrls.default.http[0], address)}`);
    if (!(await isHealthyRpcWithBalance(newChain.rpcUrls.default.http[0], address))) {
        try {
            const healthRpc = await getHealthyRpcWithBalance((await getRpcUrls(chainId.toString())).rpc, address);
            newChain.rpcUrls = {
                ...newChain.rpcUrls,
                default: {
                    http: [healthRpc],
                },
            };
        }
        catch (err) {
            console.warn(`Failed to find health rpc on chainId: ${chainId}`);
        }
    }
    return getWagmiConfig(newChain);
};
