import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Transition } from 'react-transition-group';
import { useIsDarkModeContext } from '@/context';
import close from '@/assets/new-icon/modal/close.svg';
import closeDark from '@/assets/new-icon/modal/close-dark.svg';
import { isMobileDevice } from '@/utils';
const Modal = ({ title, children, onClose, withButton, isAlert = false, className = '', isOpen, isMinHeight = false, isWalletConnect = false, isBigScreen = false, shouldTransition = true, }) => {
    const { isDarkMode } = useIsDarkModeContext();
    const transitionStyles = {
        entering: { opacity: 0 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
    };
    const ModalBody = () => (_jsxs(_Fragment, { children: [_jsx("button", { onClick: onClose, className: "cursor-pointer fixed w-full h-full z-40 top-0 left-0 modal-background" }), _jsxs("div", { className: `window fixed z-50 bg-secondary-10 dark:bg-neutral-900 rounded-16 pt-12 mobile:pt-8 pb-8 mobile:pb-4 px-8 mobile:px-4 flex flex-col ${isAlert ? 'border-8 border-error' : ''} ${isMinHeight ? 'min-h-[300px]' : ''}`, style: {
                    width: isBigScreen
                        ? isMobileDevice()
                            ? 'w-full'
                            : '37rem'
                        : '24rem',
                }, children: [_jsx("h2", { className: `title whitespace-pb-8 nowrap rounded-100 text-center ${isMobileDevice() ? 'text-h1_modal-mobile' : 'text-h1_modal'} font-medium py-2 px-4 absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 ${isAlert
                            ? 'border-4 border-_error bg-_error before:bg-_error dark:text-textColor-custom-white'
                            : 'text-neutral-800 border-secondary-10 dark:border-neutral-900 border-4 bg-primary-500 dark:bg-primary-400 dark:text-neutral-800 before:primary'}`, children: title }), withButton && (_jsx("button", { onClick: onClose, "aria-label": "close modal", className: "flex justify-center items-center absolute -top-4 -right-4 w-10 h-10 bg-neutral-800 dark:bg-secondary-900 rounded-100 z-20 hover:bg-[#464849] dark:hover:bg-secondary-800 mobile:-right-3", children: _jsx("img", { src: isDarkMode ? closeDark : close, alt: "Close Button", className: "w-5 h-5" }) })), children] })] }));
    const ModalContent = shouldTransition ? (_jsx(Transition, { in: isOpen, timeout: 150, appear: true, unmountOnExit: true, children: (state) => (_jsx("div", { style: {
                ...transitionStyles[state],
                transition: 'opacity 150ms ease-in-out',
            }, className: `font-display flex justify-center items-center fixed top-0 left-0 w-full h-full z-50 ${className}`, children: _jsx(ModalBody, {}) })) })) : (isOpen && (_jsx("div", { style: {
            opacity: 1,
        }, className: `font-display flex justify-center items-center fixed top-0 left-0 w-full h-full z-50 ${className}`, children: _jsx(ModalBody, {}) })));
    return ModalContent;
};
export default Modal;
