import '@/assets/common.css';
import { createConfig, http } from 'wagmi';
import * as allChains from 'wagmi/chains';
import { injected, coinbaseWallet, walletConnect } from 'wagmi/connectors';
import { NETWORKS, TOP_NETWORK_ID, fhenixTestnet, unSupportNetworks, } from './constants';
import { getInjectedProvider } from './utils/injectedWallet';
// There can be multiple chains with the same name, so we need to filter them out
const uniqueChains = Object.values(allChains).filter((chain, index, self) => self.findIndex((c) => c.name === chain.name) === index);
//@ts-ignore
uniqueChains.push(fhenixTestnet);
const chainsArray = [];
Object.values(uniqueChains).forEach((chain) => {
    chainsArray.push(chain);
});
const transports = {};
Object.values(uniqueChains).forEach((chain) => {
    transports[chain.id] = http();
});
const { chains } = createConfig({
    chains: [chainsArray[0], ...chainsArray.slice(1)],
    transports: transports,
});
export const moveToTopById = (arr, id = TOP_NETWORK_ID) => {
    const index = arr.findIndex((item) => item.id === id);
    if (index !== -1) {
        const [item] = arr.splice(index, 1);
        arr.unshift(item);
    }
    return arr;
};
export let supportChains = [];
chains.forEach((chain) => {
    NETWORKS.forEach((network) => {
        if (chain.id === network.networkID &&
            !unSupportNetworks.includes(chain.id)) {
            supportChains.push(chain);
        }
    });
});
export let supportUsdcChains = [];
chains.forEach((chain) => {
    NETWORKS.forEach((network) => {
        if (chain.id === network.networkID &&
            network.isUsdc &&
            !unSupportNetworks.includes(chain.id)) {
            supportUsdcChains.push(chain);
        }
    });
});
supportChains = moveToTopById(supportChains);
supportUsdcChains = moveToTopById(supportUsdcChains);
export const getWagmiConfig = (newChain) => {
    let chains = chainsArray;
    if (newChain) {
        const index = chainsArray.findIndex((chain) => chain.id === newChain.id);
        chains = [
            ...chains.slice(0, index),
            newChain,
            ...chains.slice(index + 1, chains.length),
        ];
    }
    return createConfig({
        chains: [chains[0], ...chains.slice(1)],
        transports: transports,
        connectors: [
            injected({
                target() {
                    return {
                        id: 'MetaMask',
                        name: 'MetaMask',
                        provider: () => getInjectedProvider({
                            flag: 'isMetaMask',
                        }),
                    };
                },
            }),
            coinbaseWallet(),
            injected({
                target() {
                    return {
                        id: 'Rabby',
                        name: 'Rabby Wallet',
                        provider: () => getInjectedProvider({
                            flag: 'isRabby',
                        }),
                    };
                },
            }),
            injected({
                target() {
                    return {
                        id: 'OKX',
                        name: 'OKX Wallet',
                        provider: () => getInjectedProvider({ namespace: 'okxwallet' }),
                    };
                },
            }),
            injected({
                target() {
                    return {
                        id: 'Bitget Wallet',
                        name: 'Bitget Wallet',
                        provider: () => getInjectedProvider({
                            namespace: 'bitkeep.ethereum',
                            flag: 'isBitKeep',
                        }),
                    };
                },
            }),
            walletConnect({ projectId: process.env.REACT_APP_WALLET_CONNECT_ID }),
            injected({
                target() {
                    return {
                        id: 'Taho Wallet',
                        name: 'Taho Wallet',
                        provider: () => getInjectedProvider({
                            namespace: 'tally',
                            flag: 'isTally',
                        }),
                    };
                },
            }),
        ],
        // new InjectedConnector({
        //   chains,
        //   options: {
        //     name: 'Rainbow Wallet',
        //     getProvider: () => getInjectedProvider({ flag: 'isRainbow' }),
        //     shimDisconnect: true,
        //   },
        // }),
        // new InjectedConnector({
        //   chains,
        //   options: {
        //     name: 'Trust Wallet',
        //     getProvider: () => getInjectedProvider({ flag: 'isTrustWallet' }),
        //     shimDisconnect: true,
        //   },
        // }),
        // new InjectedConnector({
        //   chains,
        //   options: {
        //     name: 'Zerion Wallet',
        //     getProvider: () => getInjectedProvider({
        //       namespace: 'zerionWallet',
        //       flag: 'isZerion',
        //     }),
        //     shimDisconnect: true,
        //   },
        // }),
        // new InjectedConnector({
        //   chains,
        //   options: {
        //     name: 'Token Pocket',
        //     getProvider: () => getInjectedProvider({
        //       flag: 'isTokenPocket',
        //     }),
        //     shimDisconnect: true,
        //   },
        // }),
        // new InjectedConnector({
        //   chains,
        //   options: {
        //     name: 'Coin98 Wallet',
        //     getProvider: () => getInjectedProvider({
        //       namespace: 'coin98Wallet',
        //       flag: 'isCoin98',
        //     }),
        //     shimDisconnect: true,
        //   },
        // }),
        //],
    });
};
export let wagmiConfig = getWagmiConfig();
